import React, { useEffect } from "react";
import ReactAG from "react-ga";

const Home = () => {
  useEffect(() => {
    ReactAG.pageview(window.location.pathname);
  }, []);
  return (
    <main>
      <span>Home</span>
    </main>
  );
};

export default Home;
