import logo from './logo.svg';
import { RouterProvider } from 'react-router';
import Router from './router';
import './App.css';
import ReactGA from "react-ga";

const TRACKING_ID = "G-Y2QW7Y30DM";

ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <RouterProvider router={Router}/>
  );
}

export default App;

